import React, {useState} from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import { Container, Box, Heading, Subhead, Section, Flex } from "../../components/ui"
import Masonry from 'react-masonry-css'
import { IoArrowBack } from "react-icons/io5";
import ImageCard from "../../components/image-card"
import CustomLightBox from "../../components/light-box"
import * as styles from "../../components/illustrations.css"

export default function Page(props) {

  const [isOpen, setOpen] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  const { contentfulIllustrationsSeries } = props.data;

  const breakpointColumnsObj = {
    default: (contentfulIllustrationsSeries.images.length === 2 || contentfulIllustrationsSeries.images.length === 4) ? 2 : 3,
    900: 1,
  };

  console.log( breakpointColumnsObj, contentfulIllustrationsSeries.images.length)

  return (
      <Layout>
      <Section>
          <Container>
          <Flex gap={3} variant="spaceBetween" alignItems={'start'}>
            <Subhead as="h1">{contentfulIllustrationsSeries.client ? contentfulIllustrationsSeries.client : contentfulIllustrationsSeries.title.title}</Subhead>
              <p className={styles.descriptionText}>{contentfulIllustrationsSeries.description.description}</p>
          </Flex>
          <Masonry
            className={styles.masonryGrid}
            columnClassName={styles.masonryGridCol}
            breakpointCols={breakpointColumnsObj}
          >
            {contentfulIllustrationsSeries.images.map((image, index) => {
              return <Box className={styles.imgContainer} key={'single'+index}>
                {image && (
                  <ImageCard
                    alt={image.alt}
                    image={getImage(image.gatsbyImageData)}
                    title={image.title}
                    file={image.file}
                    onClick={(e) => {
                      if (image.file.contentType.includes('video')) {
                        return;
                      }
                      setCurrentIndex(index);
                      setOpen(true);
                    }}
                  >
                  </ImageCard>
                )}
              </Box>
             })}
          </Masonry>
          <Link to="/illustrations" style={{
            display: 'block',
            color: '#000',
            paddingTop: '2rem',
            fontSize: '1.5rem',
          }}>
            <IoArrowBack />
            Back to Illustrations
          </Link>
          <CustomLightBox
            images={contentfulIllustrationsSeries.images.flatMap(img => {
              return img.file.contentType.includes('video') ? [false] : [{ src: img.url, alt: img.alt }]
            })}
            currentImageIndex={currentImageIndex}
            setCurrentIndex={setCurrentIndex}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          ></CustomLightBox>

        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query contentfulIllustrationsSeriesQuery2($url: String!) {
     contentfulIllustrationsSeries(url: { eq: $url }) {
      id
      title {
        title
      }
      client
      description {
        description
      }
      images {
        id
        alt
        gatsbyImageData
        url
        title
        file
      }
      url
    }
  }
`
